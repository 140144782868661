<template>
  <div class="information">
    <img src="~img/39.png" class="bg-img">
    <div class="user">
      <img :src="user.avatar" alt="">
      <p class="name">{{user.name}}</p>
    </div>
    <ul>
      <li>
        <p class="p1">头像</p>
        <div class="right">
          <img :src="user.avatar" alt="">
          <van-icon name="arrow" color="#9F9F9F" />
        </div>
        <van-uploader :after-read="afterRead" preview-size='35px' />
      </li>
      <li @click="modifyName">
        <p class="p1">昵称</p>
        <div class="right">
          <p class="p2">{{user.name}}</p>
          <van-icon name="arrow" color="#9F9F9F" />
        </div>
      </li>
      <li @click="authenticationEmail">
        <p class="p1">邮箱</p>
        <div class="right">
          <p class="p2">{{user.email}}</p>
          <p class="p3" v-if="user.credit.email_validate === 1">已认证</p>
          <p class="p3" v-else>未认证</p>
          <van-icon name="arrow" color="#9F9F9F" />
        </div>
      </li>
      <li @click="authenticationPhone">
        <p class="p1">手机</p>
        <div class="right">
          <p class="p2">{{user.phone}}</p>
          <p class="p3" v-if="user.credit.phone_validate === 1">已认证</p>
          <p class="p3" v-else>未认证</p>
          <van-icon name="arrow" color="#9F9F9F" />
        </div>
      </li>
    </ul>
    <div class="type">
      <p>账号类型</p>
      <p v-if="user.role ===1">联想人员</p>
      <p v-else>嘉宾人员</p>
    </div>
    <tabbar />
    <van-dialog v-model="show" show-cancel-button @confirm='confirmEmail' :before-close="onBeforeClose">
      <van-field v-model="email" :readonly='user.role === 1' placeholder='请输入邮箱' />
      <div class="input-box">
        <van-field v-model="codekey" type="number" center clearable placeholder="请输入图形验证码" />
        <img :src="codeImg" class="code-img" @click="getCaptch">
      </div>
      <van-field v-model="code" type="number" center clearable placeholder="请输入验证码">
        <template #button>
          <van-button size="small" type="primary" @click="getEmailCode">{{ebtnText}}</van-button>
        </template>
      </van-field>
    </van-dialog>
    <van-dialog v-model="show2" show-cancel-button @confirm='confirmPhone' :before-close="onBeforeClose">
      <van-field v-model="phone" type="number" placeholder='请输入手机号' />
      <div class="input-box">
        <van-field v-model="codekey" type="number" center clearable placeholder="请输入图形验证码" />
        <img :src="codeImg" class="code-img" @click="getCaptch">
      </div>
      <van-field v-model="code" type="number" center clearable placeholder="请输入验证码">
        <template #button>
          <van-button size="small" type="primary" @click="getPhoneCode">{{btnText}}</van-button>
        </template>
      </van-field>
    </van-dialog>
    <van-dialog v-model="show3" title="修改昵称" show-cancel-button @confirm='confirmName'>
      <van-cell-group>
        <van-field v-model="name" />
      </van-cell-group>
    </van-dialog>
    <van-overlay :show="showOverlay">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>


<script>
import tabbar from "../../components/Tabbar.vue";

export default {
  components: {
    tabbar,
  },
  data() {
    return {
      user: {
        credit: {},
      },
      show: false,
      show2: false,
      show3: false,
      showOverlay: false,
      code: "",
      email: "",
      phone: "",
      name: "",
      codekey: "",
      codeImg: "",
      key: "",
      eInterval: null,
      etime: 60,
      ebtnText: "获取验证码",
      time: 60,
      interval: null,
      btnText: "获取验证码",
    };
  },
  created() {
    this.getmyInfo();
  },
  methods: {
    async getmyInfo() {
      const res = await this.axios.get("user/info");
      console.log(res);
      if (res.code === 200) {
        this.user = res.data;
      }
    },
    afterRead(file) {
      console.log(file);
      this.showOverlay = true;
      let formData = new FormData();
      formData.append("file", file.file);
      this.updata(formData);
    },
    async updata(file) {
      const res = await this.axios.post("upload", file, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(res);
      if (res.code === 200) {
        this.showOverlay = false;
        this.user.avatar = res.data.url;
        this.setAvatar(res.data.url);
      }
    },

    async setAvatar(url) {
      const res = await this.axios.post("user/avatar", {
        avatar: url,
      });
      if (res.code === 200) {
        this.$toast("修改成功");
      } else {
        this.$toast(res.message);
      }
    },

    authenticationEmail() {
      if (this.user.role != 1) {
        this.show = true;
        this.email = this.user.email;
        this.getCaptch();
      } else {
        this.$toast("联想人员不能修改邮箱");
      }
    },
    async getEmailCode() {
      if (this.etime < 60) return;
      if (this.codekey.length <= 0) {
        this.$toast("请输入图形验证码");
        return;
      }
      if (this.checkEmail(this.email)) {
        const res = await this.axios.post("send_email", {
          push: this.email,
          captch: this.codekey,
          key: this.key,
        });
        if (res.code === 200) {
          this.$toast("发送成功");
          clearInterval(this.eInterval);
          this.eInterval = setInterval(() => {
            if (this.etime > 0) {
              this.etime--;
              this.ebtnText = this.etime + "s";
            } else {
              clearInterval(this.eInterval);
              this.ebtnText = "获取验证码";
              this.etime = 60;
            }
          }, 1000);
        } else {
          this.$toast(res.message);
        }
      } else {
        this.$toast("请输入正确的邮箱");
      }
    },
    async confirmEmail() {
      if (this.checkEmail(this.email)) {
        const res = await this.axios.post("user/check", {
          type: "email",
          push: this.email,
          code: this.code,
        });
        if (res.code === 200) {
          this.$toast("成功");
          this.code = "";
          this.codekey = "";
          this.show = false;
          this.getmyInfo();
        } else {
          this.$toast(res.message);
          this.code = "";
          this.codekey = "";
        }
      } else {
        this.$toast("请输入正确的邮箱");
      }
    },
    authenticationPhone() {
      this.show2 = true;
      this.phone = this.user.phone;
      this.getCaptch();
    },
    async getPhoneCode() {
      if (this.time < 60) return;
      if (this.codekey.length <= 0) {
        this.$toast("请输入图形验证码");
        return;
      }
      if (this.checkMobile(this.phone)) {
        const res = await this.axios.post("send_sms", {
          push: this.phone,
          captch: this.codekey,
          key: this.key,
        });
        if (res.code === 200) {
          this.$toast("发送成功");
          clearInterval(this.interval);
          this.interval = setInterval(() => {
            if (this.time > 0) {
              this.time--;
              this.btnText = this.time + "s";
            } else {
              clearInterval(this.interval);
              this.btnText = "获取验证码";
              this.time = 60;
            }
          }, 1000);
        } else {
          this.$toast(res.message);
        }
      } else {
        this.$toast("请输入正确的手机号");
      }
    },
    async confirmPhone() {
      if (this.checkMobile(this.phone)) {
        const res = await this.axios.post("user/check", {
          type: "phone",
          push: this.phone,
          code: this.code,
        });
        if (res.code === 200) {
          this.$toast("成功");
          this.code = "";
          this.codekey = "";
          this.show2 = false;
          this.getmyInfo();
        } else {
          this.$toast(res.message);
          this.code = "";
          this.codekey = "";
        }
      } else {
        this.$toast("请输入正确的手机号");
      }
    },

    onBeforeClose(action, done) {
      if (action === "confirm") {
        return done(false);
      } else {
        return done();
      }
    },

    modifyName() {
      this.show3 = true;
      this.name = this.user.name;
    },
    async confirmName() {
      const res = await this.axios.post("user/name", {
        name: this.name,
      });
      if (res.code === 200) {
        this.$toast("修改成功");
        this.getmyInfo();
      } else {
        this.$toast(res.message);
      }
    },
    checkMobile(str) {
      let re = /^1\d{10}$/;
      if (re.test(str)) {
        return true;
      } else return false;
    },
    checkEmail(str) {
      let re = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
      if (re.test(str)) {
        return true;
      } else return false;
    },
    async getCaptch() {
      const res = await this.axios.get("auth/captch");
      console.log(res);
      if (res.code === 200) {
        this.codeImg = res.data.captch.img;
        this.key = res.data.captch.key;
      }
    },
  },
};
</script>


<style lang="less" scoped>
.information {
  position: relative;
  padding-top: 96px;
  .bg-img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .user {
    position: relative;
    padding-left: 30px;
    display: flex;
    align-items: center;
    img {
      width: 111px;
      height: 111px;
      border-radius: 10px;
      margin-right: 26px;
    }
    .name {
      font-size: 32px;
      color: #fff;
    }
  }
  ul {
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
    background: #fff;
    width: 92%;
    margin: auto;
    margin-top: 79px;
    border-radius: 18px;
    li {
      display: flex;
      height: 103px;
      border-bottom: 1px solid #ebebeb;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .p1 {
        font-size: 28px;
        color: #a4a4a4;
      }
      .right {
        display: flex;
        align-items: center;
        img {
          width: 65px;
          height: 65px;
        }
        .p2 {
          font-size: 28px;
        }
        .p3 {
          font-size: 28px;
          color: #ff0000;
          margin-left: 22px;
        }
        .van-icon {
          font-size: 15px;
          margin-left: 9.5px;
        }
      }
    }
    li:last-child {
      border: none;
    }
  }
  .type {
    width: 92%;
    height: 105px;
    background: #e9e9e9;
    border-radius: 18px;
    margin: auto;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    p {
      font-size: 28px;
      color: #919191;
    }
  }
  .van-loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .van-uploader {
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translate(0, -50%);
    opacity: 0;
  }
  /deep/.van-dialog__content {
    padding-top: 20px;
    .van-cell {
      .van-cell__value {
        border-bottom: 1px solid #e9e9e9;
      }
    }
  }
  .input-box {
    position: relative;
    .code-img {
      width: 150px;
      // height: 50px;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}
</style>